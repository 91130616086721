<template>
  <div
    class="navbar-container content"
  >
    <b-toast
      v-if="isLoginAs && isParticipantPortal"
      :visible="true"
      :no-auto-hide="true"
      :no-close-button="true"
      variant="dark"
      toaster="b-toaster-top-center"
      class="bg-primary"
    >
      You are currently logged in as participant {{ currentUser.first_name }} {{ currentUser.last_name }}
    </b-toast>
    <div
      class="d-flex align-items-center"
      :class="{'justify-content-between': showProgramDropdown }"
    >
      <!-- Nav Menu Toggler -->
      <ul class="nav navbar-nav d-xl-none">
        <li class="nav-item">
          <b-link
            class="nav-link"
            @click="$emit('toggle-vertical-menu-active')"
          >
            <feather-icon
              icon="MenuIcon"
              size="21"
            />
          </b-link>
        </li>
      </ul>

      <!-- Left Col -->
      <div
        v-if="isAdminPortal"
        class="align-items-center d-none d-lg-flex flex-grow-1"
      >
        <h4 class="mb-0">
          Back Office
        </h4>
      </div>
      <div
        v-else-if="isChampionPortal || isParticipantPortal"
        class="d-none d-sm-flex"
        :class="{'flex-grow-1': !showProgramDropdown }"
      > 
        <div
          class="align-self-center mr-1"
        >
          <h4 class="mb-0">
            {{ defaultProgramName }}
          </h4>
        </div>
        <div
          class="align-self-center"
        >
          <program-dropdown
            v-if="showProgramDropdown"
            class="nav align-items-end"
          />
        </div>   
      </div>

      <!-- Right Col --> 
      <b-navbar-nav
        class="nav align-items-end"
        :class="{'ml-auto': !showProgramDropdown }"
      >
        <search-bar class="mb_10" />
        <locale
          v-if="isParticipantPortal"
          class="mb_10"
          :disabled="true"
          @select="(locale) => selectedLocale = locale"
          :color="'text-dark'"
        />
        <a
          class="text-primary mb_10"
          style="padding: 0 0.5rem"
          :href="defaultProgramPortalWithLanguage"
          target="_blank"
        >
          <feather-icon
            icon="ExternalLinkIcon"
            size="21"
          />
        </a>

        <!-- Message -->
        <b-link
          v-if="!isLoginAs && (isChampionPortal || isParticipantPortal)"
          :to="communicationRoute"
          class="text-primary mb_10"
          style="padding: 0 0.5rem"
        >
          <feather-icon
            :badge="messagesCountUnread"
            badge-classes="bg-danger"
            icon="MessageSquareIcon"
            size="21"
          />
        </b-link>

        <!-- Help -->
        <a
          v-if="isChampionPortal || isParticipantPortal"
          :href="knowledgeBaseLink"
          target="_blank"
          class="text-primary mb_10"
          style="padding: 0 0.5rem"
        >
          <feather-icon
            icon="LifeBuoyIcon"
            size="21"
          />
        </a>

        <!-- Notification -->
        <notification-dropdown
          class="mb_10"
        />

        <user-dropdown />
      </b-navbar-nav>
    </div>

    <div
      v-if="isChampionPortal || isParticipantPortal"
      class="d-flex d-sm-none justify-content-between align-items-center mt-1"
      :class="{'flex-grow-1': !showProgramDropdown }"
    > 
      <div
        class="text-truncate mr-1"
      >
        <span class="mb-0">
          {{ defaultProgramName }}
        </span>
      </div>
      <div
        class="align-self-center"
      >
        <program-dropdown
          v-if="showProgramDropdown"
          class="nav"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BToast
} from 'bootstrap-vue';
import SearchBar from '../components/app-navbar/components/SearchBar.vue';
import Locale from '../components/app-navbar/components/Locale.vue';
import ProgramDropdown from '@/layouts/components/app-navbar/components/ProgramDropdown';
import NotificationDropdown from '../components/app-navbar/components/NotificationDropdown.vue';
import UserDropdown from '../components/app-navbar/components/UserDropdown.vue';
import { userRoles as modelUserRoles } from '@/models/userRoles';
import { mapGetters } from 'vuex';
import _isEqual from 'lodash/isEqual';
import { locales } from '@models';

export default {
  components: {
    BLink,
    BToast,
    BNavbarNav,
    Locale,
    NotificationDropdown,
    UserDropdown,
    ProgramDropdown,
    SearchBar
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      selectedLocale: null,
    };
  },
  computed: {
    ...mapGetters('app', ['isAdminPortal', 'isChampionPortal', 'isParticipantPortal']),
    ...mapGetters('app', ['isAdminPortal', 'isChampionPortal', 'isParticipantPortal']),
    ...mapGetters('programs',['defaultProgramId', 'defaultProgram', 'defaultProgramPortal', 'messagesCountUnread']),
    // Participant Portal
    appCurrentProgram () {
      return this.$store.getters['app/currentProgram'];
    },
    
    isLoginAs () {
      const userData = this.currentUser;
      if (userData?.logged_in_by && Number(userData?.logged_in_by.id) !== Number(userData.id)) {
        return true;
      }
      return false;
    },
    currentUserRole() {
      return this.$store.getters['profile/profile'].role;
    },
    currentUser() {
      return this.$store.getters['profile/profile'];
    },
    currentUserPrograms() {
      return this.$store.getters['profile/profile'].programs;
    },
    showProgramDropdown() {
      return this.currentUserPrograms.length > 1;
    },
    // Champion/Admin Portal
    defaultProgramName () {
      return this.defaultProgram ? this.defaultProgram?.name : '';
    },
    defaultClientName () {
      return this.defaultProgram ? this.defaultProgram?.client?.name : '';
    },
    communicationRoute () {
      if (this.isChampionPortal) {
        return { name: "champion-program-communications" };
      }
      if (this.isParticipantPortal) {
        return { name: "participant-messages" };
      }
      return undefined;
    },
    knowledgeBaseLink () {
      const clientSubDomain = this.defaultProgram && this.defaultProgram.client ? this.defaultProgram.client.path : '';
      const programPath = this.defaultProgram ? this.defaultProgram.path : '';

      if (this.isChampionPortal) {
        return `${process.env.VUE_APP_KNOWLEDGEBASE_URL}/champion-support?oauth_url=${window.location.origin}&client_sub_domain=${clientSubDomain}&program_path=${programPath}`;
      }

      return `${process.env.VUE_APP_KNOWLEDGEBASE_URL}/participant-support?oauth_url=${window.location.origin}&client_sub_domain=${clientSubDomain}&program_path=${programPath}`;
    },
    defaultProgramPortalWithLanguage() {
      if (this.selectedLocale) {
        return `${this.defaultProgramPortal}?lang=${this.selectedLocale.id}`;
      }
      return this.defaultProgramPortal;
    }
  },
  watch: {
    defaultProgramId: {
      handler(id) {
        this.handleFetchMessagesCountUnread(this.currentUserRole, id);
      },
      immediate: true
    },
    currentUserRole: {
      handler(n, o) {
        if(_isEqual(n, o)) {
          return;
        }
        this.handleFetchMessagesCountUnread(n, this.defaultProgramId);
      }
    }
  },
  methods: {
    async fetchMessagesCountUnread(programId) {
      await this.$store.dispatch('programs/FETCH_COUNT_MESSAGES_UNREAD', programId);  
    },
    handleFetchMessagesCountUnread (role, programId) {
      if (programId && 
        (role === modelUserRoles.MENTOR || 
        role === modelUserRoles.MENTEE || 
        role === modelUserRoles.CHAMPION ||
        role === modelUserRoles.PROGRAM_ADMIN || 
        role === modelUserRoles.ADMIN)
      ) {
        this.fetchMessagesCountUnread(programId);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/base/bootstrap-extended/include';
.mb_10 {
  margin-bottom: 10px;
}
fieldset {
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 5px 10px;
  legend {
    font-size: 0.8em;
    width: auto;
  }
}

::v-deep #program-dropdown {
  .dropdown-menu {
    max-height: 500px;
    overflow-y: auto;
  }
}
.badge {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
</style>

<style lang="scss">
@import '~@/assets/scss/base/bootstrap-extended/include'; // Bootstrap includes
@import '~@/assets/scss/base/components/include'; // Components includes
@import '~@/assets/scss/vue/libs/quill.scss';
</style>
