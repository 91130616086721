<template>
  <li class="nav-item nav-search">
  
    <!-- Icon -->
    <a
      href="javascript:void(0)"
      class="nav-link nav-link-search text-primary"
      @click="showSearchBar = true"
    >
      <feather-icon
        icon="SearchIcon"
        size="21"
      />
    </a>
  
    <!-- Input -->
    <div
      class="search-input"
      :class="{'open': showSearchBar}"
    >
      <div class="search-input-icon">
        <feather-icon icon="SearchIcon" />
      </div>
      <b-form-input
        v-if="showSearchBar"
        v-model="searchQuery"
        placeholder="Search"
        autofocus
        autocomplete="off"
        @keyup.esc="showSearchBar = false; resetSearchQuery()"
        @input="onInput"
        @keyup.enter="onEnter"
      />
      <div
        class="search-input-close"
        @click="showSearchBar = false; resetSearchQuery()"
      >
        <feather-icon icon="XIcon" />
      </div>
  
      <!-- Suggestions List -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="search-list search-list-main scroll-area overflow-hidden"
        :class="{'show': searchQuery}"
        tagname="ul"
      >
        <li
          v-for="(suggestion_list, grp_name, grp_index) in data"
          :key="grp_index"
          class="suggestions-groups-list"
        >
  
          <!-- Group Header -->
          <p class="suggestion-group-title">
            <span>
              {{ title(grp_name === 'topics'? 'communications': grp_name) }}
            </span>
          </p>
  
          <!-- Suggestion List of each group -->
          <ul>
            <li
              v-for="(suggestion, index) in suggestion_list.items"
              :key="index"
              class="suggestion-group-suggestion cursor-pointer"
            >
              <b-link
                v-if="grp_name === 'clients'"
                class="p-0"
                @click="handleClick(grp_name, suggestion)"
              >
                <feather-icon
                  icon="CheckSquareIcon"
                  class="mr-75"
                />
                <span class="align-middle">{{ suggestion.name }}</span>
              </b-link>
              <b-link
                v-else-if="grp_name === 'programs'"
                class="p-0"
                @click="handleClick(grp_name, suggestion)"
              >
                <feather-icon
                  icon="ListIcon"
                  class="mr-75"
                />
                <span class="align-middle">{{ suggestion.name }}</span>
              </b-link>
              <template v-else-if="grp_name === 'participants' || grp_name === 'champions'">
                <div 
                  class="d-flex align-items-center"
                  @click="handleClick(grp_name, suggestion)"
                >
                  <b-avatar
                    :text="userInitials(suggestion)"
                    variant="primary"
                    class="mr-1"
                    size="32"
                  />
                  <div>
                    <p>{{ suggestion.full_name }}</p>
                    <small>{{ suggestion.email }}</small>
                  </div>
                </div>
              </template>
              <template v-else-if="grp_name === 'resources'">
                <div 
                  class="d-flex align-items-center"
                  @click="handleClick(grp_name, suggestion)"
                >
                  <feather-icon
                    icon="ListIcon"
                    class="mr-75"
                  />
                  <div>
                    <p>{{ suggestion.translations.length? suggestion.translations[0].title: "" }}</p>
                  </div>
                </div>
              </template>
              <b-link
                v-else-if="grp_name === 'applications' || grp_name === 'surveys'"
                class="p-0"
                @click="handleClick(grp_name, suggestion)"
              >
                <feather-icon
                  icon="CheckSquareIcon"
                  class="mr-75"
                />
                <span class="align-middle">{{ suggestion.translations.length? suggestion.translations[0].title: "" }}</span>
              </b-link>
              <b-link
                v-else-if="grp_name === 'trainings'"
                class="p-0"
                @click="handleClick(grp_name, suggestion)"
              >
                <feather-icon
                  icon="CheckSquareIcon"
                  class="mr-75"
                />
                <span class="align-middle">{{ suggestion.course_name }}</span>
              </b-link>
              <b-link
                v-else-if="grp_name === 'topics'"
                class="p-0"
                @click="handleClick(grp_name, suggestion)"
              >
                <feather-icon
                  icon="CheckSquareIcon"
                />
                <span class="align-middle">{{ suggestion.messages[0].subject }}</span>
              </b-link>
              <b-link
                v-else-if="grp_name === 'notes'"
                class="p-0"
                @click="handleClick(grp_name, suggestion)"
              >
                <feather-icon
                  icon="CheckSquareIcon"
                  class="mr-75"
                />
                <span class="align-middle">{{ suggestion.note_text }}</span>
              </b-link>
            </li>
  
            <li
              v-if="!suggestion_list.items.length && searchQuery"
              class="suggestion-group-suggestion no-results"
            >
              <p>No Results Found.</p>
            </li>
          </ul>
        </li>
      </vue-perfect-scrollbar>
    </div>
  </li>
</template>
  
  <script>
  import {
    BFormInput, BLink, BImg, BAvatar,
  } from 'bootstrap-vue';
  import VuePerfectScrollbar from 'vue-perfect-scrollbar';
  import { title } from '@core/utils/filter';

  import { searchService } from '@/services';
  import { resourceTypes } from "@/models/eResources";
  import { mapGetters } from 'vuex';
  import { topicTypes } from '@/models';
  
  export default {
    components: {
      BFormInput,
      BLink,
      BAvatar,
      VuePerfectScrollbar,
    },
    data() {
      return {
        searchQuery: "",
        showSearchBar: false,
        perfectScrollbarSettings: {
          maxScrollbarLength: 60
        },
        data: [],
        resourceTypes
      };
    },
    computed: {
      ...mapGetters('app', ['isAdminPortal', 'isChampionPortal']),
      ...mapGetters('programs',['defaultProgramId']),
    },
    methods: {
      userInitials(user) {
        const firstInt = Array.from(user.first_name)[0];
        const lastInt = Array.from(user.last_name)[0];
        return `${firstInt}${lastInt}`;
      },
      onInput(val) {
        this.getSearchResult(val);
      },
      onEnter() {
        this.showSearchBar = false; 
        const val = this.searchQuery;
        this.resetSearchQuery();
        if (this.isChampionPortal) {
          return this.$router.push({
            name: 'champion-search',
            params: {id: this.defaultProgramId},
            query: { query: val },
          });
        }
        return this.$router.push({
          name: 'admin-search',
          query: { query: val },
        });
      },
      resetSearchQuery() {
        this.searchQuery = '';
      },
      handleClick(type, suggestion) {
        if (type === 'clients') {
          if (this.isAdminPortal) {
            this.$router.push({
              name: 'admin-client',
              params: { clientId: suggestion.id },
            });
          }
          return false;
        } else if (type === 'programs') {
          this.$router.push({
            name: this.isChampionPortal? 'champion-home': 'admin-program',
            params: { id: suggestion.id },
          });
        } else if (type === 'participants') {
          const userRole = suggestion.user_roles.find(ur => ur.program_id !== null);
          if (userRole) {
            this.$router.push({
              name: 'champion-program-participant',
              params: { id: userRole.program_id, participantId: suggestion.id},
            });
          }
        } else if (type === 'champions') {
          var userRole = suggestion.user_roles.find(ur => ur.client_id !== null);
          if (userRole && this.isAdminPortal) {
            this.$router.push({
              name: 'admin-client',
              params: { clientId: userRole.client_id},
              query: { id: suggestion.id},
              hash: '#champions'
            });
          } else {
            userRole = suggestion.user_roles.find(ur => ur.program_id !== null);
            if (userRole) {
              this.$router.push({
                name: 'admin-program',
                params: { id: userRole.program_id},
                query: { id: suggestion.id},
                hash: '#champions'
              });
            } else {
              return false;
            }
          }
        } else if (type === 'resources') {
          if (suggestion.is_library && this.isAdminPortal) {
            this.$router.push({
              name: 'admin-resources-list',
              query: {
                id: suggestion.id
              }
            });
          } else {
            this.$router.push({
              name: 'champion-program-resources',
              params: { id: suggestion.programs[0].id },
              query: {
                id: suggestion.id
              }
            });
          }
        } else if (type === 'applications') {
          if (suggestion.program_id) {
            this.$router.push({
              name: 'champion-program-application',
              params: { application: suggestion.id, id: suggestion.program_id },
            });
          } else if (this.isAdminPortal) {
            this.$router.push({
              name: 'admin-application',
              params: { application: suggestion.id },
            });
          }
        } else if (type === 'surveys') {
          if (suggestion.program_id === null) {
            if (this.isAdminPortal) {
              this.$router.push({
                name: 'admin-survey',
                params: { survey: suggestion.id },
              });
            }
          } else {
            this.$router.push({
              name: this.isChampionPortal? 'champion-program-survey': 'admin-program-survey',
              params: { id: suggestion.program_id, survey: suggestion.id },
            });
          }
        } else if (type === 'topics') {
          if (suggestion.program_id === null) {
            if (this.isAdminPortal) {
              this.$router.push({
                name: 'admin-communications-list',
                query: {
                  id: suggestion.id
                }
              });
            }
          } else {
            if (suggestion.is_schedulable) {
              this.$router.push({
                name: 'champion-program-schedules',
                params: { id: suggestion.program_id },
                query: {
                  scheduledId: suggestion.id
                }
              });
            } else if (suggestion.type_id === topicTypes.INVITE){
              this.$router.push({
                name: 'champion-program-invites',
                params: { id: suggestion.program_id },
                query: {
                  id: suggestion.id
                }
              });
            } else {
              this.$router.push({
                name: 'champion-program-communications',
                params: { id: suggestion.program_id },
                query: {
                  id: suggestion.id
                }
              });
            }
          }
        } else if (type === 'trainings') {
          if (this.isAdminPortal) {
            this.$router.push({
              name: 'admin-training-list',
              query: {
                id: suggestion.id
              }
            });
          } else if(suggestion.program_trainings.length) {
            this.$router.push({
              name: 'champion-program-training-list',
              params: { id: suggestion.program_trainings[0].program_id },
              query: {
                id: suggestion.id
              }
            });
          }
        } else if (type === 'notes') {
          this.$router.push({
            name: 'champion-program-participant',
            params: { id: suggestion.program_id, participantId: suggestion.notable_id },
            hash: "#notes"
          });
        }
        this.showSearchBar = false; 
        this.resetSearchQuery();
      },
      async getSearchResult(val) {
        try {
          const options = {
            search: {
              page: 1,
              perPage: 5,
            },
            query: val
          };
          const response = this.isAdminPortal? await searchService.getSearchResult(options): await searchService.getProgramSearchResult(this.defaultProgramId, options);
          this.data = response.data;
        } catch (e) {
          console.log(e);
          const { status, data } = e.response;
          //server may respond with vaidation errors
          if (status === 422 && data.errors) {
            this.$refs.observer.setErrors(data.errors);
          } else {
            this.$log.error(e);
          } 
          this.$toast(makeErrorToast("Programs list not loaded."));
          return;
        } finally {
          this.isLoading = false;
        }
      },
    },
    setup() {
      return {
        title
      };
    }
  };
  </script>
  
  <style lang="scss" scoped>
  @import '~@/assets/scss/base/bootstrap-extended/include';
  @import '~@/assets/scss/base/components/variables-dark';
  
  ul
  {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  p {
    margin: 0;
  }
  
  /* .app-auto-suggest {
    position: relative;
  }
  
  .auto-suggest-suggestions-list {
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
    border-radius: 6px;
    position: absolute;
    top: calc(100% + 1rem);
    background-color: white;
    width: 100%;
  } */
  
  .suggestion-group-title {
    font-weight: 500;
    padding: .75rem 1rem .25rem;
  }
  
  .suggestion-group-suggestion {
    padding: .75rem 1rem
  }
  
  .suggestion-current-selected {
    background-color: $body-bg;
  
    .dark-layout & {
      background-color: $theme-dark-body-bg;
    }
  }
  </style>
  